import '@ten-netzkundenportal/ui-components/dist/base.css';
import { registerApplication, start } from 'single-spa';
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout';

import { startApplicationInsights } from './applicationInsights';
import './base.css';
import microfrontendLayout from './microfrontend-layout.html';
import { DASHBOARD } from './routes';

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
    routes,
    loadApp({ name }) {
        return System.import(name);
    },
});
startApplicationInsights();

registerApplication(
    'header',
    () => import('./header/index.app').then((module) => module.default),
    (location) => location.pathname !== '/redirect',
);

registerApplication(
    'index',
    () => import('./index/index.app').then((module) => module.default),
    (location) => location.pathname === '/index.html' || location.pathname === '/',
);

registerApplication(
    'redirect',
    () => import('./redirect/index.app').then((module) => module.default),
    (location) => location.pathname === '/redirect',
);

registerApplication(
    'redirect-email-link',
    () => import('./redirect-email-link/index.app').then((module) => module.default),
    (location) => location.pathname.startsWith('/redirect-email/'),
);

registerApplication(
    'redirect-old-routes',
    () => import('./redirect-old-routes/index.app').then((module) => module.default),
    (location) =>
        ['/uebersicht-installationsbetrieb', '/uebersicht-projektpartner', '/uebersicht-kunde'].some((r) =>
            location.pathname.startsWith(r),
        ),
);

registerApplication(
    'footer',
    () => import('./footer/index.app').then((module) => module.default),
    () => true,
);

registerApplication(
    'impressum',
    () => import('./impressum/index.app').then((module) => module.default),
    (location) => location.pathname.startsWith('/impressum'),
);

registerApplication(
    'networkConnectionDashboard',
    () => import('./dashboard/index.app').then((module) => module.default),
    (location) => location.pathname === DASHBOARD || location.pathname === `${DASHBOARD}/`,
);

const layoutEngine = constructLayoutEngine({ routes, applications, active: false });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
