import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { stringify } from '@ten-netzkundenportal/logging-utils';
import { isAxiosError } from 'axios';

import appConfig from './app.config';

const isAppInsightsLoggingEnabled = appConfig.enableFrontendAppInsightsErrorLogging;
const appInsightsConnectionString = appConfig.applicationInsightsConnectionString;

const originalConsole = window.console;

export const startApplicationInsights = () => {
    const isLocalDev = window.location.host.includes('localhost') || window.location.host.includes('127.0.0.1');

    if (!isLocalDev && !!appInsightsConnectionString && isAppInsightsLoggingEnabled) {
        const appInsights = getAppInsightsInstance();
        window.console = overrideConsole(appInsights);
    }
};

function getAppInsightsInstance() {
    const reactPlugin = new ReactPlugin();

    const appInsights = new ApplicationInsights({
        config: {
            connectionString: appInsightsConnectionString,
            extensions: [reactPlugin],
            loggingLevelTelemetry: 0, // Do not send internal Application Insights errors as telemetry
            disableFetchTracking: true,
            disableAjaxTracking: true,
            isStorageUseDisabled: true,
            disableCookiesUsage: true,
        },
    });
    appInsights.loadAppInsights();

    return appInsights;
}

function overrideConsole(appInsights: ApplicationInsights) {
    return {
        ...originalConsole,
        error: (...itemsToBeLogged: unknown[]) => {
            originalConsole.error(...itemsToBeLogged);

            try {
                const sanitizedLogEntries = itemsToBeLogged.map(sanitizeLogEntryForAppInsights);
                appInsights.trackTrace({
                    message: JSON.stringify(sanitizedLogEntries),
                    severityLevel: SeverityLevel.Error,
                });
                appInsights.flush();
            } catch (e) {
                originalConsole.error('Error during trackTrace', e);
            }
        },
    };
}

function sanitizeLogEntryForAppInsights(logEntry: unknown) {
    if (isAxiosError(logEntry)) {
        const request = {
            method: logEntry.config?.method,
            url: logEntry.config?.url,
        };

        const response = {
            code: logEntry.code,
            message: logEntry.message,
            status: logEntry.response?.status,
            statusText: logEntry.response?.statusText,
        };

        return stringify({ request, response, stack: logEntry.stack, name: logEntry.name });
    }

    return stringify(logEntry);
}
